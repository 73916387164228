<template>
  <div>
    <CHeader fixed id="dateselector-header" class="vue-fixed-header" style="z-index:1028">
      <div style="padding-top:10px">
        <CButtonGroup>
          <CDropdown
            :toggler-text="selected_simple_date_range.label"
            :v-model="selected_simple_date_range"
            color="timefilter"
          >
            <div
              v-for="(option, index) in dayOptions"
              :key="index"
              v-on:click="selectSimpleDateRange(option)"
            >
              <CDropdownItem :value="option.days">{{ option.label }}</CDropdownItem>
            </div>
          </CDropdown>
          <date-picker
            v-show="customDateRange"
            size="sm"
            class="info"
            v-model="dates"
            type="date"
            range
            placeholder="Select date range"
            @change="selectDateRange"
          ></date-picker>
          <CDropdown :toggler-text="time_period" :v-model="time_period" color="timefilter">
            <div
              v-for="(option, index) in chartDisplayOptions"
              :key="index"
              v-on:click="selectGranularity(option)"
            >
              <CDropdownItem :value="option.value">{{ option.label }}</CDropdownItem>
            </div>
          </CDropdown>
        </CButtonGroup>
      </div>
    </CHeader>
    <CRow class="custom">
      <CCol sm="8">
        <CCard>
          <CCardHeader class="p-3 px-4">
            <h5 id="traffic" class="card-title mb-0">{{ $t('HOME.SUMMARY') }}</h5>
          </CCardHeader>
          <CCardBody class="p-0">
            <CContainer>
              <CRow>
                <CCol sm="6" class="border-right p-4">
                  <div
                    class="small text-muted mb-2"
                    v-c-tooltip="{content: 'amount of gross sales from transaction which successfully paid by customers', placement: 'left'}"
                  >
                    <span>{{ $t('HOME.SUCCESS_TRANSACTIONS') }}</span>
                  </div>
                  <h3 :v-show="showTransVolChart">Rp {{ formatAmount(getChartDetails('TransVolChart','total')) }}</h3>
                  <TransactionVolumeChart
                    :v-show="showTransVolChart"
                    Col="Info"
                    Name="Success Transaction Volume"
                    :Max="getChartDetails('TransVolChart','max')"
                    :DataSets="getChartDetails('TransVolChart','dataset')"
                    :Labels="getChartDetails('TransVolChart','label')"
                    Type="amount"
                    style="height: 200px; margin-top: 10px"
                  />
                </CCol>
                <CCol sm="6" class="p-4">
                  <div
                    class="small text-muted mb-2"
                    v-c-tooltip="{content: 'number of total success transaction made by customer',placement: 'left' }"
                  >
                    <span>{{ $t('HOME.TOTAL_SUCCESS_TRANSACTIONS') }}</span>
                  </div>
                  <h3  :v-show="showTransSuccessChart">{{ getChartDetails('TransSuccessChart','total') }}</h3>
                  <TransactionVolumeChart
                    :v-show="showTransSuccessChart"
                    Col="Succ"
                    Name="Total Success Transaction"
                    :Max="getChartDetails('TransSuccessChart','max')"
                    :DataSets="getChartDetails('TransSuccessChart','dataset')"
                    :Labels="getChartDetails('TransSuccessChart','label')"
                    Type="count"
                    style="height: 200px; margin-top: 10px"
                  />
                </CCol>
              </CRow>
            </CContainer>
          </CCardBody>
        </CCard>
      </CCol>
      <CCol sm="4">
        <CCard style="height: 390px; margin-top: 0px">
          <CCardHeader class="p-3 px-4">
            <h5 id="traffic" class="card-title mb-0" v-c-tooltip="{content: 'recent 3 transferred fund to your bank account', placement: 'left' }">{{ $t('HOME.SETTLEMENTS') }}</h5>
            <a v-on:click="goTo('/settlements/')" class="view-more float-right">{{ $t("COMMON.VIEW_DETAILS") }}</a>
          </CCardHeader>
          <CCardBody class="px-0 pt-0">
            <!-- <div class="remaining-settlement-div">
              <span class="remaining-settlement-text">Remaining Settlement</span>
              <span class="remaining-settlement-value">Rp. {{ formatAmount(remainingSettlement) }}</span>
            </div> -->

            <CContainer :v-show="showSettlements">
              <CCard
                v-for="(settlement, index) in settlements.slice(0, 3)"
                :key="index"
                class="settlements"
              >
                <CCardHeader>
                  <CRow>
                    <CCol sm="10">
                      <div class="col-12 p-0">
                        <strong>{{ formatAmount(settlement.total_amount) }} {{ settlement.currency }}</strong> {{ $t("HOME.MSG") }}
                        <CBadge color="success">{{ formatStatus(settlement.status) }}</CBadge>
                      </div>
                      <div class="float-left text-muted">
                        <span>{{ formatDate(settlement.settled_at) }}</span>
                      </div>
                    </CCol>
                  </CRow>
                </CCardHeader>
              </CCard>
            </CContainer>
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>
    <CCard>
      <CCardHeader class="p-3 px-4">
        <h5 id="traffic" class="card-title mb-0">{{ $t("HOME.ANALYTICS") }}</h5>
        <a v-on:click="goTo('/analytics/')" class="view-more float-right">{{ $t("COMMON.VIEW_DETAILS") }}</a>
      </CCardHeader>
      <CCardBody class="p-0">
        <CContainer class="m-0">
          <CRow class="p-2 mt-2 border-bottom">
            <CCol sm="2">
              <div class="small text-muted mb-4">{{ $t('HOME.PAYMENT_STATUS') }}</div>
              <div :v-show="showPayStatusChart">
              <div>
                <span class="badge-legend badge bg-info">&nbsp;</span>
                <span>{{ $t("HOME.STARTED") }}</span>
              </div>
              <strong class="percentage-legend">{{ getStatusPercentage('started') }}%</strong>
              <div
                v-c-tooltip="{content: 'Number of transactions that created but still have not been paid yet', placement: 'left' }"
              >
                <span class="badge-legend badge bg-warning">&nbsp;</span>
                <span>{{ $t("HOME.PROCESSING") }}</span>
              </div>
              <strong class="percentage-legend">{{ getStatusPercentage('processing') }}%</strong>
              <div>
                <span class="badge-legend badge bg-danger">&nbsp;</span>
                <span>{{ $t("HOME.FAILED") }}</span>
              </div>
              <strong class="percentage-legend">{{ getStatusPercentage('failed') }}%</strong>
              <div
                v-c-tooltip="{content: 'Number of transactions that created and successfully paid', placement: 'left' }"
              >
                <span class="badge-legend badge bg-success">&nbsp;</span>
                <span>{{ $t("HOME.COMPLETED") }}</span>
              </div>
              <strong class="percentage-legend">{{ getStatusPercentage('completed') }}%</strong>
              </div>
            </CCol>
            <CCol sm="10">
              <PaymentStatusChart
                :v-show="showPayStatusChart"
                Col="Info"
                Name="Payment Status"
                :Max="getChartDetails('PaymentStatusChart','max')"
                :DataSets="getChartDetails('PaymentStatusChart','dataset')"
                :Labels="getChartDetails('PaymentStatusChart','label')"
                class="mb-4"
                style="height: 300px;"
              />
            </CCol>
          </CRow>
          <CRow class="p-2 mt-2">
            <CCol sm="2">
              <div
                class="small text-muted mb-4"
                v-c-tooltip="{content: 'Distribution of transcations across top payment methods', placement: 'left' }"
              >{{ $t("HOME.PAYMENT_METHODS")}}</div>
              <div>
                <span class="badge-legend badge bg-info">&nbsp;</span>
                <span>{{ $t("HOME.CARD") }}</span>
              </div>
              <strong class="percentage-legend">{{ topPayMethods.card }}%</strong>
              <div>
                <span class="badge-legend badge bg-success">&nbsp;</span>
                <span>{{ $t("HOME.VA") }}</span>
              </div>
              <strong class="percentage-legend">{{ topPayMethods.va }}%</strong>
              <div>
                <span class="badge-legend badge bg-warning">&nbsp;</span>
                <span>{{ $t("HOME.WALLET") }}t</span>
              </div>
              <strong class="percentage-legend">{{ topPayMethods.ewallet }}%</strong>
            </CCol>
            <CCol sm="10">
              <PaymentDistributionChart
                :v-show="showPayDistChart"
                Col="Info"
                Name="Payment Distribution"
                :Max="getChartDetails('PaymentDistributionChart','max')"
                :DataSets="getChartDetails('PaymentDistributionChart','dataset')"
                :Labels="getChartDetails('PaymentDistributionChart','label')"
                class="mb-4"
                style="height: 300px;"
              />
            </CCol>
          </CRow>
        </CContainer>
      </CCardBody>
    </CCard>
    <CCard>
      <CCardBody class="p-0">
        <CContainer>
          <CRow>
            <CCol sm="4" class="border-right p-4">
              <h5
                v-c-tooltip="{content: 'Number of transaction made customer using Durianpay for the first time', placement:'left' }"
              >{{ $t("HOME.NEW_TRANSACTIONS") }}</h5>
              <TransactionVolumeChart
                :v-show="showNewTransChart"
                Col="Info"
                Name="New Transactions"
                :Max="getChartDetails('TransNewChart','max')"
                :DataSets="getChartDetails('TransNewChart','dataset')"
                :Labels="getChartDetails('TransNewChart','label')"
                style="height: 275px; margin-top: 10px"
              />
            </CCol>
            <CCol sm="4" class="p-4">
              <h5
                v-c-tooltip="{content: 'Number of transaction from customer that has transacted using Durianpay before', placement: 'left' }"
              >{{ $t("HOME.REPEAT_TRANSACTIONS") }}</h5>
              <TransactionVolumeChart
                :v-show="showRepeatTransChart"
                Col="Succ"
                Name="Repeat Transactions"
                :Max="getChartDetails('TransRepeatChart','max')"
                :DataSets="getChartDetails('TransRepeatChart','dataset')"
                :Labels="getChartDetails('TransRepeatChart','label')"
                style="height: 275px; margin-top: 10px"
              />
            </CCol>
            <CCol sm="4" class="p-4">
              <h5 id="traffic" class="card-title mb-0">{{ $t("HOME.PRODUCTS")}}</h5>
              <TopSellProductChart
                :v-show="showTopSellProdChart"
                :DataSets="getChartDetails('TopSellProdChart','dataset')"
                :Labels="getChartDetails('TopSellProdChart','label')"
                :Total="getChartDetails('TopSellProdChart','total')"
                style="height: 300px; margin-top: 0px"
              />
            </CCol>
          </CRow>
        </CContainer>
      </CCardBody>
    </CCard>
  </div>
</template>
<script>
import TransactionVolumeChart from "./charts/TransactionVolumeChart";
import PaymentDistributionChart from "./charts/PaymentDistributionChart";
import PaymentStatusChart from "./charts/PaymentStatusChart";
import TopSellProductChart from "./charts/TopSellProductChart";

import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";
import {
  getSuccessTransaction,
  getRecentSettlement,
  getTopSellingProducts,
  getRemainingSettlement
} from "@/api/home.api.js";

export default {
  name: "Home",
  components: {
    TransactionVolumeChart,
    PaymentDistributionChart,
    PaymentStatusChart,
    TopSellProductChart,
    DatePicker
  },
  data() {
    return {
      chartDisplayOptions: [
        { label: "Daily", value: "daily" },
        { label: "Weekly", value: "weekly" },
        { label: "Monthly", value: "monthly" }
      ],
      dayOptions: [
        { label: "Today", days: 1 },
        { label: "Last 7 days", days: 7 },
        { label: "Last 2 weeks", days: 14 },
        { label: "Last 30 days", days: 30 },
        { label: "Last 3 months", days: 90 },
        { label: "Last 1 year", days: 365 },
        { label: "Custom Date Range", days: null }
      ],
      selected_simple_date_range: { label: "Last 30 days", days: 30 },
      time_period: "daily",
      days: 0,
      customDateRange: false,
      range: {
        start: new Date(2020, 0, 1),
        end: new Date()
      },
      dates: [],
      showTransVolChart: false,
      showTransSuccessChart: false,
      showPayDistChart: false,
      showPayStatusChart: false,
      showSettlements: false,
      showNewTransChart: false,
      showRepeatTransChart: false,
      showTopSellProdChart: false,
      chart: {
        id: '',
        dataset: null,
        labels: [],
        max: 0,
        percentage: 0,
        total: 0,
        details: null
      },
      allCharts: [],
      datasetPaymentDistribution: {
        card: [],
        va: [],
        ewallet: [],
        total: []
      },
      label3: [],
      max3: 0,
      datasetPaymentStatus: {
        started: [],
        processing: [],
        failed: [],
        completed: [],
        total: []
      },
      label4: [],
      max4: 0,
      topPayMethods: {
        card: 0,
        va: 0,
        ewallet: 0
      },
      status: {
        processing: 0,
        started: 0,
        failed: 0,
        completed: 0
      },
      settlements: [],
      cardCollapse0: false,
      cardCollapse1: false,
      cardCollapse2: false,
      remainingSettlement: 0
    };
  },
  methods: {
    selectSimpleDateRange(val) {
      this.selected_simple_date_range = val;
      if(val.days === null) {
        this.customDateRange = true;
      } else {
        this.customDateRange = false;
        if (this.days != val.days) {
          this.days = val.days;
          this.getDatesFromDays(this.selected_simple_date_range);
        }
      }
    },
    selectGranularity(val) {
      if (this.time_period != val.value) {
        this.time_period = val.value;
        this._refreshCharts();
      }
    },
    selectDateRange() {
      if (this.ifValid(this.dates[0]) && this.ifValid(this.dates[1])) {
        this._refreshCharts();
      }
    },
    getDatesFromDays(val) {
      let today = new Date();
      let before = new Date();
      before.setDate(before.getDate() - val.days);
      this.dates = [
        new Date(before.getFullYear(), before.getMonth(), before.getDate()),
        new Date(today.getFullYear(), today.getMonth(), today.getDate())
      ];
      this._refreshCharts();
    },
    _refreshCharts() {
      this.status.completed = 0;
      this.status.failed = 0;
      this.status.processing = 0;
      this.status.started = 0;
      this.allCharts = []
      this.getTransactionChart();
      this.getPaymentDistribuionChart();
      this.getPaymentStatusChart();
      this.getNewRepeatCustomerTransChart();
      this.getTopSellingProductsChart();
    },
    getTransactionChart() {
      const params = this.defaultParams();
      params.append("status", "completed");
      getSuccessTransaction(params)
        .then(response => {
          const err = this.verifyHttpResponse(response);
          if (err === false) {
            this.formatDataforGraph(response.data.data.payments);
          }
        })
        .catch(this.handleAPIError);
    },
    getTopSellingProductsChart() {
      const params = this.defaultParams();
      params.append("status", "completed");
      getTopSellingProducts(params)
        .then(response => {
          const err = this.verifyHttpResponse(response);
          if (err === false) {
            this.formatDataforTopSellGraph(response.data.data.products);
          }
        })
        .catch(this.handleAPIError);
    },
    getNewRepeatCustomerTransChart() {
      const params = this.defaultParams();
      params.append("customer_distribution", "all");
      getSuccessTransaction(params)
        .then(response => {
          const err = this.verifyHttpResponse(response);
          if (err === false) {
            this.formatDataforNewRepeatTransGraph(response.data.data);
          }
        })
        .catch(this.handleAPIError);
    },
    getPaymentDistribuionChart() {
      const params = this.defaultParams();
      params.append("status", "completed");
      params.append("type_distribution", "all");
      getSuccessTransaction(params)
        .then(response => {
          const err = this.verifyHttpResponse(response);
          if (err === false) {
            this.formatDataforPayDistGraph(response.data.data);
          }
        })
        .catch(this.handleAPIError);
    },
    getPaymentStatusChart() {
      const params = this.defaultParams();
      params.append("status", "all");
      getSuccessTransaction(params)
        .then(response => {
          const err = this.verifyHttpResponse(response);
          if (err === false) {
            this.formatDataforPayStatusGraph(response.data.data);
          }
        })
        .catch(this.handleAPIError);
    },
    getRecentSettlements() {
      let paramsMap = new Map();
      paramsMap['skip'] = 0;
      paramsMap['limit'] = 3;
      paramsMap['search'] = "";
      paramsMap['filters'] = JSON.stringify([]);
      getRecentSettlement(this.generatePostParams(paramsMap))
        .then(response => {
          const err = this.verifyHttpResponse(response);
          if (err === false && response.data.data.settlements != null) {
            this.settlements = response.data.data.settlements;
            this.showSettlements = true;
          }
        })
        .catch(this.handleAPIError);
    },
    getRemainingSettlement() {
      getRemainingSettlement()
        .then(response => {
          this.remainingSettlement = response.data.data
        })
      .catch(err => {
        this.handleAPIError(err)
      })
    },
    initializeChart() {
      return JSON.parse(JSON.stringify(this.chart));
    },
    formatDataforTopSellGraph(data) {
      if (data == null) return;
      this.showTopSellProdChart = false;
      let chartTopSellProd = this.initializeChart();
      chartTopSellProd.id = 'TopSellProdChart';
      chartTopSellProd.dataset = [];
      for (let i = 0; i < data.length; i++) {
        const element = data[i];
        chartTopSellProd.dataset.push(element.count);
        chartTopSellProd.total = chartTopSellProd.total + element.count;
        chartTopSellProd.labels.push(element.name);
      }
      this.allCharts.push(chartTopSellProd)
      this.showTopSellProdChart = true;
    },
    formatDataforPayStatusGraph(data) {
      if (data == null) return;

      this.showPayStatusChart = false;
      let total_count = 0;
      let total_started = 0;
      let total_processing = 0;
      let total_failed = 0;
      let total_completed = 0;
      let chartPayStatus = this.initializeChart();
      chartPayStatus.id = 'PaymentStatusChart';
      chartPayStatus.dataset = JSON.parse(JSON.stringify(this.datasetPaymentStatus));
      chartPayStatus.details = this.status;
      for (let i = 0; i < data.started.length; i++) {
        const element = data.started[i];
        chartPayStatus.dataset.started.push(element.count);
        total_started = total_started + element.count;
        chartPayStatus.labels.push(
          this.getLabel(element.from, element.to, this.time_period, i)
        );
      }
      for (let i = 0; i < data.processing.length; i++) {
        const element = data.processing[i];
        chartPayStatus.dataset.processing.push(element.count);
        total_processing = total_processing + element.count;
      }
      for (let i = 0; i < data.failed.length; i++) {
        const element = data.failed[i];
        chartPayStatus.dataset.failed.push(element.count);
        total_failed = total_failed + element.count;
      }
      for (let i = 0; i < data.payments.length; i++) {
        const element = data.payments[i];
        chartPayStatus.dataset.completed.push(element.count);
        total_completed = total_completed + element.count;
      }

      for (let i = 0; i < data.all.length; i++) {
        const element = data.all[i];
        chartPayStatus.dataset.total.push(element.count)
        total_count = total_count + element.count;
      }
      if (total_count != 0) {
        chartPayStatus.details.started = Math.round((total_started / total_count) * 100);
        chartPayStatus.details.processing = Math.round(
          (total_processing / total_count) * 100
        );
        chartPayStatus.details.failed = Math.round((total_failed / total_count) * 100);
        chartPayStatus.details.completed = Math.round(
          (total_completed / total_count) * 100
        );
      }
 
      if (total_count > 10) {
        let max = [
          Math.max(...chartPayStatus.dataset.started),
          Math.max(...chartPayStatus.dataset.failed),
          Math.max(...chartPayStatus.dataset.processing),
          Math.max(...chartPayStatus.dataset.completed)
        ];
        chartPayStatus.max = Math.max(...max) + 10;
      } else {
        chartPayStatus.max = 10;
      }
      this.status = chartPayStatus.details
      this.allCharts.push(chartPayStatus)
      this.showPayStatusChart = true;
    },
    formatDataforPayDistGraph(data) {
      if (data == null) return;
      this.showPayDistChart = false;
      let total_count = 0;
      let total_card = 0;
      let total_va = 0;
      let total_ewallet = 0;
      let chartPayDist = this.initializeChart();
      chartPayDist.id = 'PaymentDistributionChart';
      chartPayDist.dataset = JSON.parse(JSON.stringify(this.datasetPaymentDistribution));
      chartPayDist.details = this.topPayMethods;
      for (let i = 0; i < data.card.length; i++) {
        const element = data.card[i];
        chartPayDist.dataset.card.push(element.count);
        total_card = total_card + element.count;
      }
      for (let i = 0; i < data.va.length; i++) {
        const element = data.va[i];
        chartPayDist.dataset.va.push(element.count);
        total_va = total_va + element.count;
      }
      for (let i = 0; i < data.ewallet.length; i++) {
        const element = data.ewallet[i];
        chartPayDist.dataset.ewallet.push(element.count);
        total_ewallet = total_ewallet + element.count;
      }

      for (let i = 0; i < data.all.length; i++) {
        const element = data.all[i];
        chartPayDist.dataset.total.push(element.count);
        total_count = total_count + element.count;
        chartPayDist.labels.push(
          this.getLabel(element.from, element.to, this.time_period, i)
        );
      }

      if (total_count != 0) {
        chartPayDist.details.card = Math.round((total_card / total_count) * 100);
        chartPayDist.details.va = Math.round((total_va / total_count) * 100);
        chartPayDist.details.ewallet = Math.round(
          (total_ewallet / total_count) * 100
        );
      }

      if (total_count > 10) {
        chartPayDist.max = total_count;
      } else {
        chartPayDist.max = 10;
      }
      this.topPayMethods = chartPayDist.details
      this.allCharts.push(chartPayDist)
      this.showPayDistChart = true;
    },
    getChartDetails(id, key) {
      var chartMap = new Map(this.allCharts.map(obj => [obj.id, obj]));
      var item = chartMap.get(id)
      if (item !== undefined) {
        return this.getValueByKey(item, key);
      }
      return '';
    },
    getValueByKey(data, key) {
      switch (key) {
        case 'dataset':
          return data.dataset;
        case 'label':
          return data.labels;
        case 'max':
          return data.max;
        case 'total':
          return data.total;
        case 'status':
          return data.details.status;
        default:
          break;
      }
    },
    formatDataforGraph(data) {
      if (data == null) return;
      this.showTransVolChart = false;
      this.showTransSuccessChart = false;
      let chartTransVol = this.initializeChart();
      let chartTransSucc = this.initializeChart();
      chartTransVol.id = 'TransVolChart';
      chartTransSucc.id = 'TransSuccessChart';
      chartTransVol.dataset = [];
      chartTransSucc.dataset = [];     
      for (let i = 0; i < data.length; i++) {
        const element = data[i];
        if (element.total_amount != "") {
          chartTransVol.total = parseFloat(chartTransVol.total) + parseFloat(element.total_amount);
        }
        
        chartTransSucc.total = chartTransSucc.total + element.count;
        
        chartTransVol.dataset.push(element.total_amount);
        
        chartTransSucc.dataset.push(element.count);
        
        chartTransVol.labels.push(
          this.getLabel(element.from, element.to, this.time_period, i)
        );
      }

      chartTransVol.total = chartTransVol.total.toFixed(2);

      chartTransSucc.labels = chartTransVol.labels;
      if (Math.max(...chartTransVol.dataset) > 10) {
        chartTransVol.max = Math.max(...chartTransVol.dataset);
      } else {
        chartTransVol.max = 10;
      }
      if (Math.max(...chartTransSucc.dataset) > 10) {
        chartTransSucc.max = Math.max(...chartTransSucc.dataset);
      } else {
        chartTransSucc.max = 10;
      }

      this.allCharts.push(chartTransVol)
      this.allCharts.push(chartTransSucc)
      this.showTransVolChart = true;
      this.showTransSuccessChart = true;
    },
    formatDataforNewRepeatTransGraph(data) {
      if (data == null) return;

      this.showNewTransChart = false;
      this.showRepeatTransChart = false;
      let total_count = 0;
      let chartTransNew = this.initializeChart();
      let chartTransRepeat = this.initializeChart();
      chartTransNew.id = 'TransNewChart';
      chartTransRepeat.id = 'TransRepeatChart';
      chartTransNew.dataset = [];
      chartTransRepeat.dataset = [];
      for (let i = 0; i < data.payments.length; i++) {
        const element = data.payments[i];
        total_count = total_count + element.count;
      }
      for (let i = 0; i < data.new.length; i++) {
        const element = data.new[i];
        chartTransNew.dataset.push(element.count);
        chartTransNew.total = chartTransNew.total + element.count;
        chartTransNew.labels.push(
          this.getLabel(element.from, element.to, this.time_period, i)
        );
      }
      for (let i = 0; i < data.repeat.length; i++) {
        const element = data.repeat[i];
        chartTransRepeat.dataset.push(element.count);
        chartTransRepeat.total = chartTransRepeat.total + element.count;
        chartTransRepeat.labels.push(
          this.getLabel(element.from, element.to, this.time_period, i)
        );
      }
      if (Math.max(...chartTransNew.dataset) > 10) {
        chartTransNew.max = Math.max(...chartTransNew.dataset);
      } else {
        chartTransNew.max = 10;
      }
      if (Math.max(...chartTransRepeat.dataset) > 10) {
        chartTransRepeat.max = Math.max(...chartTransRepeat.dataset);
      } else {
        chartTransRepeat.max = 10;
      }
      chartTransNew.percentage = (chartTransNew.total / total_count) * 100;
      chartTransRepeat.percentage = (chartTransRepeat.total / total_count) * 100;

      this.allCharts.push(chartTransNew)
      this.allCharts.push(chartTransRepeat)
      this.showNewTransChart = true;
      this.showRepeatTransChart = true;
    },
    getLabel(from, to, time_period) {
      let date = new Date(this.moment(from).format("DD-MMM-YYYY"));
      switch (time_period) {
        case "daily":
          return this.moment(from).format("DD-MMM");
        case "monthly":
          return this.getMonth(date.getMonth());
        case "weekly":
          return (
            this.moment(from).format("DD-MMM") +
            "-" +
            this.moment(to).format("DD-MMM")
          );
        default:
          break;
      }
    },
    formatStatus(status) {
      switch (status) {
        case "settled":
          return "Settled";
      }
    },
    getStatusPercentage(key) {
      switch (key) {
        case 'completed':
            return this.status.completed;
        case 'started':
            return this.status.started;
        case 'processing':
            return this.status.processing;
        case 'failed':
            return this.status.failed;
        default:
            return 0;
      }
    },
    defaultParams() {
      const params = new URLSearchParams();
      params.append(
        "from",
        this.moment(this.dates[0])
          .format("YYYY-MM-DD")
          .toString()
      );
      params.append(
        "to",
        this.moment(this.dates[1])
          .format("YYYY-MM-DD")
          .toString()
      );
      params.append("time_period", this.time_period);
      params.append("is_live", !this.$store.getters.getIsSandboxActive);
      return params;
    }
  },
  created() {
    this.selectSimpleDateRange(this.selected_simple_date_range);
    this.getRecentSettlements();
    this.getRemainingSettlement();
  },
  mounted() {
    window.onscroll = scrollShowNav;
    const navEl = document.getElementsByTagName("header")[0];
    const filterEl = document.getElementsByTagName("header")[1];
    const navOffset = navEl.offsetHeight;
    function scrollShowNav() {
      if (window.pageYOffset >= navOffset){
        filterEl.style.zIndex = 1029;
        navEl.style.paddingLeft = "1em";
      } else {
        filterEl.style.zIndex = 1028;
        navEl.style.paddingLeft = "";
      }
    }
  },
  watch: {
    '$store.getters.getIsSandboxActive': function() {
      this._refreshCharts();
      this.getRemainingSettlement();
    }
  },
  computed: {
    defaultRange() {
      let range = [];
      let today = new Date();
      let before = new Date();
      before = before.setDate(today.getDate() - 30);
      range.push(before);
      range.push(today);
      this.dates = range;
    }
  }
};
</script>
<style scoped>
.btn-secondary {
  width: 150px;
}
.mx-datepicker-range {
  width: 250px;
}
.hr {
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}
.vr {
  margin-left: 1rem;
  margin-right: 1rem;
  border: 0;
  border-right: 1px solid rgba(0, 0, 0, 0.1);
}
.settlements {
  border-color: lightskyblue;
}
.wrapper {
  display: flex;
  justify-content: space-between;
}
.filter {
  background: none;
}
.btn-timefilter {
  background-color: grey;
  color: black;
  border-color: black;
}
.card-title {
  font-family: "Apercu";
  float: left;
}
.percentage-legend {
  font-size: 22px;
  margin-bottom: 5px;
  margin-left: 22px;
  float: left;
  width: 100%;
}
.badge-legend {
  display: block;
  min-width: 20px;
  width: 20px;
  margin-right: 5px;
  height: 10px;
  border-radius: 2px;
  float: left;
  margin-top: 5px;
}
h3,
.h3 {
  font-family: "Apercu";
  font-style: normal;
  font-weight: bold;
  font-size: 26px;
  line-height: 32px;
  margin-bottom: 1em;
}
.custom {
  padding-top: 10px;
}
.dateselector-header {
  background: none;
}
.vue-fixed-header--isFixed {
  position: fixed;
}

.remaining-settlement-div {
  height: 3rem;
  width: 100%;
  background-color: #F9F9F9;
  color: #4E4F52;
  left: 0;
  top: 0;
  padding: 0px 24px 0px 24px;
  display: flex;
  align-items: center;
  border: 1px solid #E9E9F3;
  border-top: 0 !important;
}

.remaining-settlement-text {
  font-size: 12px;
}

.remaining-settlement-value {
  position: absolute;
  right: 24px;
  font-size: 14px;
  font-weight: 700;
}
</style>
