<template>
  <CChartLine
    :datasets="defaultDatasets"
    :options="defaultOptions"
    :labels="labels"
  />
</template>

<script>
import { CChartLine } from "@coreui/vue-chartjs";
import { getStyle, hexToRgba } from "@coreui/utils/src";

export default {
  name: "TransactionVolumeChart",
  components: {
    CChartLine,
  },
  props: ["DataSets", "Labels", "Max", "Col", "Name", "Type"],
  computed: {
    defaultDatasets() {
      const brandInfo = getStyle('info') || '#418AF8';
      return [
        {
          label: this.Name,
          backgroundColor: hexToRgba("#725CF6", 10),
          borderColor: '#725CF6',
          pointHoverBackgroundColor: brandInfo,
          borderWidth: 2,
          data: this.DataSets,
        },
      ];
    },
    labels() {
      let labels = this.Labels;
      return labels;
    },
    defaultOptions() {
      const vm = this
      return {
        maintainAspectRatio: false,
        legend: {
          display: false,
        },
        scales: {
          xAxes: [
            { 
              ticks: {
                maxTicksLimit: 5,
                fontFamily: 'Apercu',
                fontSize: 10,
                fontColor: '#aaa',
                maxRotation: 0,
              },
              gridLines: {
                display: false,
              },
            },
          ],
          yAxes: [
            {
              ticks: {
                callback: function(label) {
                  if (label > 1000) {
                    return label/1000+'k';
                  }
                  return label;
                },
                fontFamily: 'Apercu',
                fontSize: 10,
                fontColor: '#aaa',
                beginAtZero: false,
                maxTicksLimit: 5,
              },
              gridLines: {
                color: '#eee',
                display: true,
              },
            },
          ],
        },
        tooltips: {
          callbacks: {
            label: function(tooltipItems, data) {
              if (vm.Type == "amount") {
              return tooltipItems.yLabel.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".") + " IDR";
              }
              return tooltipItems.yLabel
            }
          }
        },
        elements: {
          line: {
            tension: 0,
          },
          point: {
            radius: 0,
            hitRadius: 10,
            hoverRadius: 4,
            hoverBorderWidth: 3,
          },
        },
      };
    },
  },
};
</script>
