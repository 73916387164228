import { httpClient } from './httpClient.js';

const
    GETSUCCESSTRANSACTION = '/dashboard/stats/payments',
    GETRECENTSETTLEMENTS = '/dashboard/settlements',
    GETTOPSELLPRODUCTS = 'dashboard/stats/products',
    REMAINING_SETTLEMENT = '/dashboard/remaining-settlements';

const
    getSuccessTransaction = (filterParams) => httpClient.dashboardService.get(GETSUCCESSTRANSACTION, { "params": filterParams }),
    getRecentSettlement = (filterParams) => httpClient.dashboardService.get(GETRECENTSETTLEMENTS, { "params": filterParams }),
    getTopSellingProducts = (filterParams) => httpClient.dashboardService.get(GETTOPSELLPRODUCTS, { "params": filterParams }),
    getRemainingSettlement = () => httpClient.dashboardService.get(REMAINING_SETTLEMENT);

export {
    getSuccessTransaction,
    getRecentSettlement,
    getTopSellingProducts,
    getRemainingSettlement
};
