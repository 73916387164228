<template>
  <CChartLine
    :datasets="defaultDatasets"
    :options="defaultOptions"
    :labels="labels"
  />
</template>

<script>
import { CChartLine } from "@coreui/vue-chartjs";
import { getStyle, hexToRgba } from "@coreui/utils/src";

export default {
  name: "PaymentDistributionChart",
  components: {
    CChartLine,
  },
  props: ["DataSets", "Labels", "Max", "Col", "Name"],
  computed: {
    defaultDatasets() {
      const brandSuccess = getStyle('success2') || '#4dbd74'
      const brandInfo = getStyle('info') || '#20a8d8'
      const brandDanger = getStyle('danger') || '#f86c6b'
      const brandWarning = getStyle('warning') || '#ffcc00'
      
      return [
        {
          label: "card",
          backgroundColor: hexToRgba(brandInfo, 10),
          borderColor: brandInfo,
          pointHoverBackgroundColor: brandInfo,
          borderWidth: 2,
          data: this.DataSets.card,
        },
        {
          label: "va",
          backgroundColor: hexToRgba(brandSuccess, 10),
          borderColor: brandSuccess,
          pointHoverBackgroundColor: brandSuccess,
          borderWidth: 2,
          data: this.DataSets.va,
        },
        {
          label: "ewallet",
          backgroundColor: hexToRgba(brandWarning, 10),
          borderColor: brandWarning,
          pointHoverBackgroundColor: brandWarning,
          borderWidth: 2,
          data: this.DataSets.ewallet,
        },
        {
          label: "total",
          backgroundColor: hexToRgba(brandDanger, 10),
          borderColor: brandDanger,
          pointHoverBackgroundColor: brandDanger,
          borderWidth: 1,
          data: this.DataSets.total,
        },
      ];
    },
    labels() {
      let labels = this.Labels;
      return labels;
    },
    defaultOptions() {
      return {
        maintainAspectRatio: false,
        legend: {
          display: true,
        },
        scales: {
          xAxes: [
            {
              ticks: {
                beginAtZero: false,
                maxTicksLimit: 10,
                fontFamily: 'Apercu',
                fontSize: 10,
                fontColor: '#aaa',
                maxRotation: 0,
              },
              gridLines: {
                display: false,
              },
            },
          ],
          yAxes: [
            {
              ticks: {
                beginAtZero: false,
                maxTicksLimit: 6,
                fontFamily: 'Apercu',
                fontSize: 10,
                fontColor: '#aaa',
                maxRotation: 0,
              },
              gridLines: {
                color: '#eee',
                display: true,
              },
            },
          ],
        },
        elements: {
          line: {
            tension: 0,
          },
          point: {
            radius: 0,
            hitRadius: 10,
            hoverRadius: 4,
            hoverBorderWidth: 3,
          },
        },
      };
    },
  },
};
</script>
